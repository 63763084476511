import React from "react";
import { FaInstagram } from "react-icons/fa"; // Instagram icon from react-icons

const LandingPage = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center vh-100"
      style={{ backgroundColor: "black" }}
    >
      <div className="text-center">
        <img
          src="landing_page.jpeg"
          alt="Landing"
          className="img-fluid mb-4 rounded"
          style={{ width: "500px", height: "auto" }}
        />
        <div>
          <a href="https://www.instagram.com/theentrepreneurholic" className="btn btn-light">
            <FaInstagram size={50} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
